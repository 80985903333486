import React from 'react';

export default function Detection() {
  return (
    <svg
      width='203.000000'
      height='203.000000'
      viewBox='0 0 203 203'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id='clip1_5949'>
          <rect
            id='Frame 11'
            rx='101.500000'
            width='203.000000'
            height='203.000000'
            fill='white'
            fill-opacity='0'
          />
        </clipPath>
        <clipPath id='clip1_5955'>
          <rect
            id='wifi-signal 1'
            width='36.555557'
            height='36.555557'
            transform='translate(83.222168 83.222229)'
            fill='white'
            fill-opacity='0'
          />
        </clipPath>
        <clipPath id='clip1_5954'>
          <rect
            id='Frame 9'
            rx='31.500000'
            width='63.000000'
            height='63.000000'
            transform='translate(70.000000 70.000000)'
            fill='white'
            fill-opacity='0'
          />
        </clipPath>
        <linearGradient
          x1='158.277542'
          y1='41.611115'
          x2='163.816681'
          y2='126.000000'
          id='paint_linear_1_5953_0'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#20BE74' stop-opacity='0.000000' />
          <stop
            offset='0.388542'
            stop-color='#20BE74'
            stop-opacity='0.164706'
          />
          <stop offset='1.000000' stop-color='#20BE74' />
        </linearGradient>
      </defs>
      <rect
        id='Frame 11'
        rx='101.500000'
        width='203.000000'
        height='203.000000'
        fill='#1C9960'
        fill-opacity='0.400000'
      />
      <g clip-path='url(#clip1_5949)'>
        <rect
          id='Frame 10'
          rx='68.833336'
          width='137.666672'
          height='137.666672'
          transform='translate(32.666504 32.666656)'
          fill='#1C9960'
          fill-opacity='0.500000'
        />
      </g>
      <mask
        id='mask1_5952'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0.000000'
        y='0.000000'
        width='203.000000'
        height='203.000000'
      >
        <circle
          id='Ellipse 1'
          cx='101.500000'
          cy='101.500000'
          r='101.500000'
          fill='#D9D9D9'
          fill-opacity='1.000000'
        />
      </mask>
      <g mask='url(#mask1_5952)'>
        <path
          id='Vector 3'
          d='M131.25 111.94L205.33 126L205.33 26.44L126.38 82.44L129.89 88.29C129.98 88.44 130.06 88.6 130.11 88.76L132.35 95.58C132.43 95.81 132.47 96.06 132.47 96.31L132.47 104.71C132.47 104.85 132.46 104.98 132.44 105.11L131.25 111.94Z'
          fill='url(#paint_linear_1_5953_0)'
          fill-opacity='1.000000'
          fill-rule='evenodd'
        />
      </g>
      <rect
        id='Frame 9'
        rx='31.500000'
        width='63.000000'
        height='63.000000'
        transform='translate(70.000000 70.000000)'
        fill='#1C9960'
        fill-opacity='1.000000'
      />
      <g clip-path='url(#clip1_5954)'>
        <rect
          id='wifi-signal 1'
          width='36.555557'
          height='36.555557'
          transform='translate(83.222168 83.222229)'
          fill='#FFFFFF'
          fill-opacity='0'
        />
        <g clip-path='url(#clip1_5955)'>
          <path
            id='Vector'
            d='M99 98.88C97.57 100.31 97.57 102.62 99 104.06C100.42 105.49 102.74 105.49 104.17 104.06C105.6 102.63 105.6 100.32 104.17 98.88C102.74 97.45 100.42 97.45 99 98.88Z'
            fill='#FFFFFF'
            fill-opacity='1.000000'
            fill-rule='nonzero'
          />
          <path
            id='Vector'
            d='M109.34 93.71C108.63 92.99 107.47 92.99 106.76 93.71C106.04 94.42 106.04 95.58 106.76 96.29C109.61 99.15 109.61 103.79 106.76 106.64C106.04 107.35 106.04 108.51 106.76 109.22C107.11 109.58 107.59 109.76 108.05 109.76C108.52 109.76 108.99 109.58 109.34 109.22C113.62 104.95 113.62 97.99 109.34 93.71Z'
            fill='#FFFFFF'
            fill-opacity='1.000000'
            fill-rule='nonzero'
          />
          <path
            id='Vector'
            d='M96.4 93.71C95.69 92.99 94.53 92.99 93.82 93.71C91.75 95.78 90.61 98.53 90.61 101.47C90.61 104.4 91.75 107.16 93.82 109.23C94.18 109.58 94.65 109.76 95.11 109.76C95.58 109.76 96.05 109.58 96.4 109.23C97.12 108.51 97.12 107.36 96.4 106.64C93.55 103.79 93.55 99.15 96.4 96.3C97.13 95.58 97.13 94.43 96.4 93.71Z'
            fill='#FFFFFF'
            fill-opacity='1.000000'
            fill-rule='nonzero'
          />
          <path
            id='Vector'
            d='M114.51 88.54C113.8 87.82 112.64 87.82 111.93 88.54C111.21 89.25 111.21 90.41 111.93 91.12C117.63 96.83 117.63 106.11 111.93 111.81C111.21 112.53 111.21 113.68 111.93 114.4C112.28 114.75 113.56 115.52 114.51 114.4C121.02 106.69 121.64 95.67 114.51 88.54Z'
            fill='#FFFFFF'
            fill-opacity='1.000000'
            fill-rule='nonzero'
          />
          <path
            id='Vector'
            d='M91.24 91.12C91.95 90.41 91.95 89.25 91.24 88.54C90.52 87.82 89.36 87.82 88.65 88.54C81.52 95.67 81.68 107.11 88.65 114.4C89.63 115.43 90.88 114.76 91.24 114.4C91.95 113.69 91.95 112.53 91.24 111.82C85.53 106.11 85.53 96.83 91.24 91.12Z'
            fill='#FFFFFF'
            fill-opacity='1.000000'
            fill-rule='nonzero'
          />
        </g>
      </g>
      <path
        id='Vector 4'
        d='M200.27 124.83L131.05 111.61'
        stroke='#2BFF9C'
        stroke-opacity='1.000000'
        stroke-width='1.555556'
      />
    </svg>
  );
}
