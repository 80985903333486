export default function pluginExample(userConfig) {
  return {
    name: 'fb-pixel',
    config: {
      trackingId: userConfig.trackingId
    },
    initialize: ({ config }) => {
      /* eslint-disable */
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', config.trackingId);
      fbq('track', 'PageView');
    },
    loaded() {
      return typeof window.fbq !== 'undefined';
    },
    track: ({ payload }) => {
      window.fbq('track', payload.event);
    }
  };
}
