import trim from 'lodash.trim';

export default function gtagPlugin(userConfig) {
  return {
    name: 'gtag',
    config: {
      containerId: userConfig.containerId
    },
    initialize: ({ config }) => {
      /* eslint-disable */
      const { containerId } = config;
      (function (e, t) {
        const r = t.createElement('script');
        r.type = 'text/javascript';
        r.async = true;
        r.src = `https://www.googletagmanager.com/gtag/js?id=${containerId}`;
        const i = t.getElementsByTagName('script')[0];
        i.parentNode.insertBefore(r, i);
      })(window, document);
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', containerId, { send_page_view: false });
      /* eslint-enable */
    },
    loaded() {
      return typeof window.gtag !== 'undefined';
    },
    page({ payload, config }) {
      window.gtag('config', config.containerId, {
        page_title: payload.properties.title,
        page_location: payload.properties.url
      });
    },
    track: ({ payload }) => {
      window.gtag('event', trim(payload.event, '_'), payload.properties);
    },
    identify: ({ payload, config }) => {
      window.gtag('config', config.containerId, { user_id: payload.userId });
      window.gtag('set', payload.traits);
    }
  };
}
